$(window).on('click', function (e) {

    if(e.target.getAttribute("data-id") == "icon_close"){
        e.preventDefault();
        $("#filter").removeClass("responsive-show");
        $(".filter_type #filter_2").removeClass("show")
    }
});

$(window).on('click', function (e) {
    const element = document.querySelector(".responsive-filter")
    if(e.target == element){
        e.preventDefault();
        $("#filter").addClass("responsive-show");
    }
});

$(window).on('click', function (e) {
    const id = e.target.getAttribute("data-id");
    const element = document.body;
    const printElement = document.getElementById(id);

    if (e.target.getAttribute("data-selector") == "export") {
        const isMobile = $(window).width() < 500;
        const opt = {
            margin: 1,
            filename: isMobile ? 'lista_supermercado_mobile.pdf' : 'lista_supermercado.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: {
                width: isMobile ? 800 : 800, // Ajusta el ancho según el dispositivo
                scale: isMobile ? 2 : 2, // Ajusta la escala para mejorar el rendimiento en móviles
                useCORS: true, // Habilita CORS si hay imágenes externas
                logging: false, // Desactiva logging para mejorar rendimiento
            },
            jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'portrait'
            }
        };
        html2pdf().set(opt).from(printElement).save();
    }
});


$(".chosen-select").on('change', function (e) {
    console.log('change');
    const selectedValue = $(this).val();  // Obtiene el valor del select con la clase chosen-select
    const period = $(this).data('period'); // Obtiene el valor de data-period

    if (selectedValue) {
        $.ajax({
            url: `/ingredientes/${selectedValue}/units`,  // Reemplaza con tu ruta correcta
            method: 'GET',
            dataType: 'json',
            success: function(data) {
                // Usar el period para actualizar el input correcto
                const unitInput = $(`#unit-input-${period}`);  // Seleccionar el input con el id dinámico

                // Actualiza el valor del input con la unidad recibida
                unitInput.val(data.name);
            },
            error: function() {
                console.error("Error al obtener las unidades.");
            }
        });
    }
});

$(document).on('submit', 'form.custom-form', function(event) {
    event.preventDefault(); // Prevenir el envío tradicional del formulario

    var form = $(this);
    var url = form.attr('action'); // Obtener la URL de la acción del formulario
    var formData = form.serialize(); // Serializar los datos del formulario

    // Realizar la solicitud AJAX
    $.ajax({
        url: url,
        method: 'POST',
        data: formData,
        dataType: 'script', // Esperar una respuesta en formato JavaScript
        success: function(response) {
            console.log('Formulario enviado correctamente');
        },
        error: function(xhr, status, error) {
            console.error('Error en el envío del formulario:', error);
        }
    });
});


