// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from "@rails/ujs";
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element,{
      group:{
        name: 'shared',
        pull: 'clone',
        put: false
      },
      sort:false,
      animation: 150,
      onEnd: this.end.bind(this),
      onSelect: this.change.bind(this),
      onStart: this.start.bind(this),
      handle: ".dropstart-menu",  // Specifies which items inside the element should be draggable

    })

  }
  start(e){
    const element = e.item
    const heightWindow = window.innerWidth;
    console.log("ineerWindow",heightWindow)
    const toolkit = element.querySelector(".toolkit")

    // window.scrollTo(0, heightWindow * -0.5);

  }
  change(e){
    const element = e

  }
  end(e){
    let recipeID = e.item.dataset.id // ID Receta
    let menuID = e.to.dataset.menu
    const loading = document.getElementById("loading")
    console.log(e.to.dataset)
    console.log(e.item.dataset)
      let data = new FormData()
      data.append('recipeID', recipeID)
      Rails.ajax({
        url: `/menus/${menuID}/move`,
        type: 'PATCH',
        data:data,
        success: function(response){
          loading.classList.remove("d-none")
          const element = document.getElementById("ajax_success");
          element.classList.remove("d-none")




          // setTimeout(function() {


          // }, 2000);
          document.addEventListener('DOMContentLoaded', function() {
            // your code here
            loading.classList.add("d-none")
            element.classList.add("d-none")
          }, false);
        },
        error: function(err){
          const element = document.getElementById("ajax_error");
          element.classList.remove("d-none")
          setTimeout(function() {
            element.classList.add("d-none")
          }, 2000);
        }
      })
    }
}
