// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from "@rails/ujs";
import Sortable from "sortablejs"

export default class extends Controller {

  updateTag(event) {
    const selectElement = event.target;
    const url = selectElement.dataset.url;
    const tagValue = selectElement.value;

    Rails.ajax({
      url: url,
      type: "PATCH",
      data: new URLSearchParams({ tag: tagValue }).toString(),
      success: (response) => {
      },
      error: (error) => {
        console.error("Failed to update tag", error);
      }
    });
  }
}
